import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaShoppingCart, FaUser } from 'react-icons/fa';
import { BsDot } from "react-icons/bs";
import './Footer.css';  // Importando o novo arquivo CSS
import { useAuth } from '../../contexts/AuthContext';


const Footer = ({ selectedFlavors = [] }) => {
  const navigate = useNavigate();
  const [cartItemCount, setCartItemCount] = useState(0);
  const { isAuthenticated } = useAuth(); // Usando o contexto para definir autenticação

  useEffect(() => {
    const updateCartCount = () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      const count = cart.reduce((accumulator, item) => accumulator + item.quantity, 0);
      setCartItemCount(count);
    };    

    updateCartCount();
    window.addEventListener('storage', updateCartCount);

    return () => {
      window.removeEventListener('storage', updateCartCount);
    };
  }, []);

  const goToHome = () => {
    navigate('/');
  };
  const goToCart = () => {
    navigate('/cart');
  };
  
  const gotToPerfil = () => {
    if (isAuthenticated) {
      navigate('/perfilusuario');
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <div className="footerContainer">
        {selectedFlavors.length > 0 && (
          <div className="selectedFlavors">
            <h4 className="footerTitulo">Sabores Selecionados:</h4>
            <ul>
            <li>
              {selectedFlavors.map((flavor) => (
                <span key={flavor.id} className="flavorItem">
                  <BsDot className="dotIcon"/>{flavor.descricao}
                </span>
              ))}
            </li>
            </ul>
          </div>
        )}
        <footer className="footer">
          <button onClick={goToHome} className="footerButton"><FaHome /> Início</button>
          {cartItemCount > 0 && (
            <button onClick={goToCart} className="footerButton">
              <FaShoppingCart />
              {cartItemCount > 0 && <span className="cartCount">{cartItemCount}</span>}
              {' '}Carrinho
            </button>
          )}
          {/* removido por enquanto
          <button onClick={goToPedidos} className="footerButton"><FaClipboardList /> Pedidos</button>
          */}
          <button onClick={gotToPerfil} className="footerButton"><FaUser /> Perfil</button>
        </footer>
      </div>
    </>
  );
};

export default Footer;