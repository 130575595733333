import { formatCurrency } from "../utils/numberUtils";
import { formatTime } from "../utils/dateTimeUtils";

// Função externa para 6 via WhatsApp
export const handleEnviarPedido = (pedidoData) => {
    const { nomeCliente, pedidoItens, tipoPagamento, tipoEntrega, valorTotal, taxaEntrega } = pedidoData;

    let endereco;

    if (tipoEntrega.descricao === "Delivery") {
      if (pedidoData.endereco) {
      endereco = pedidoData.endereco.logradouro+` Nº: ${pedidoData.endereco.numero}
        Bairro: ${pedidoData.endereco.bairro}
        ${(pedidoData.endereco.complemento || pedidoData.endereco.complemento==='' ? 'Complemento: '+pedidoData.endereco.complemento : '')}`;
      } else {
        endereco = pedidoData.enderecoCliente;
      }
    }


    // Define os dados de entrega, se o tipo de entrega for "Delivery"
    let dadosEntrega = tipoEntrega.descricao === "Delivery" ? `
    ▶ DADOS PARA ENTREGA
        Endereço: ${endereco}
        Taxa de Entrega: *${formatCurrency(taxaEntrega)}*` : '';
      let subTotal = tipoEntrega.descricao === "Delivery" ? `
        SUBTOTAL: *${formatCurrency(valorTotal-taxaEntrega)}*      
        ------------------------------------------` : '';
    // Monta a mensagem do pedido
    const mensagem = `Previsao de Conclusão: *${formatTime(pedidoData.dataHoraPrevisao)}*
      
*✅ RESUMO DO PEDIDO Nº ${pedidoData.id}*
        Cliente: ${pedidoData.cliente?.nome ? pedidoData.cliente?.nome : nomeCliente}
${pedidoItens.map((item) => 
`       ${item.qtde}x ${item.produto.descricao.toUpperCase()} (${formatCurrency(item.valor)})` +
(item.pedidoItemSabores && Array.isArray(item.pedidoItemSabores) ? 
item.pedidoItemSabores.map((sabor) => (      
`\n         ${sabor.sabor.codSabor} - ${sabor.sabor.descricao} `
)) : '') // Adiciona uma string vazia se não for um array
  + (item.observacao ? `\nObs: ${item.observacao}` : '') // Adiciona observação se estiver preenchida
)}
        ------------------------------------------      
  Modo de Entrega: *${tipoEntrega.descricao}*${dadosEntrega}
        -  -  -  -  -  -  -  -  -  -  - -  -  -  -${subTotal}      
        ▶ *TOTAL = ${formatCurrency(valorTotal)}*
        ------------------------------------------  
        ▶ *PAGAMENTO ${tipoPagamento.descricao.toUpperCase()}*
      `;
  
    let phoneNumber = '';
      phoneNumber = 'phone=555596333319';
      //phoneNumber = 'phone=555597045438';
  
    // Gera a URL do WhatsApp com a mensagem codificada
    const whatsappUrl = `whatsapp://send?${phoneNumber}&text=${encodeURIComponent(mensagem)}`;

    console.log(whatsappUrl);
    

    return whatsappUrl;
  };
  