import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes, FaCartPlus } from 'react-icons/fa'; // Importe os ícones
import Header from '../components/Form/Header';
import Footer from '../components/Form/Footer';
import MainContent from '../components/Form/MainContent';
import { formatCurrency } from '../utils/numberUtils';

import './DrinkDetails.css'; // Importe o arquivo CSS

const DrinkDetails = () => {
  const { state } = useLocation();
  const { beer } = state || {};
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(beer ? beer.valor : 0);
  const [observation, setObservation] = useState("");

  if (!beer) {
    return <p>Produto não encontrado.</p>;
  }

  const addToCart = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart.push({ ...beer, quantity, observation });
    localStorage.setItem('cart', JSON.stringify(cart));

    navigate('/cart');
  };

  const increaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    setTotalPrice(beer.valor * newQuantity);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      setTotalPrice(beer.valor * newQuantity);
    }
  };

  const cancelAction = () => {
    navigate(-1);
  };

  return (
    <>
      <Header titulo="Adicionar Item" />
      <MainContent>
        <h1 className="title">{beer.descricao}</h1>
        <div className='containerGeral'>
          <img src={`data:image/jpeg;base64,${beer.imagem.arquivo}`} alt={beer.descricao} className="beerImg" />
          <div>
            {/*<p><strong>Grupo:</strong> {beer.grupo.descricao}</p> */}  
            {/*<p><strong>Variação:</strong> {beer.variacao.descricao}</p> */}                      
            {/** <p><strong>Preço:</strong> {formatCurrency(beer.valor)}</p> */}
            
            <p style={{ fontSize: '22px' }}><strong>Quantidade:</strong> {quantity}</p>
            <button 
              className="button" 
              style={{ width: '45px', height: '45px', fontSize: '24px', padding: '10px' }} 
              onClick={decreaseQuantity}
            >
              -
            </button>
            <button 
              className="button" 
              style={{ width: '45px', height: '45px', fontSize: '24px', padding: '10px' }} 
              onClick={increaseQuantity}
            >
              +
            </button>
            
            <p><strong>Total:</strong> R$ {totalPrice.toFixed(2)}</p>            
          </div>
        </div>

        <div className="observationContainer">
          <div className="ingredientesLabel">
            <p><strong>Ingredientes:</strong> {beer.ingredientes}</p>
          </div>
          <label className="observacaoLabel" htmlFor="observation" style={{ marginTop: '-10px' }}>Observação:</label>
          <textarea
            id="observation"
            rows="2"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            placeholder="Adicione uma observação sobre este item"
            className="textarea"
          />
          <div className="buttonContainer" style={{ marginTop: '0px' }}>
            <button className="cancelButton" onClick={cancelAction}>
              <FaTimes /> Cancelar
            </button>
            <button className="addToCartButton" onClick={addToCart}>
              <FaCartPlus /> Adicionar ao Carrinho
            </button>            
          </div>
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default DrinkDetails;
