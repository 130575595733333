// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth } from './contexts/AuthContext'; // Importando o hook useAuth

// Telas
import BeerMenu from './pages/BeerMenu';
import DrinkDetails from './pages/DrinkDetails';
import Cart from './pages/Pedido/Cart';
import Home from './pages/Home';
import Endereco from './pages/Pedido/Endereco';
import Pagamento from './pages/Pedido/Pagamento';
import Resumo from './pages/Pedido/Resumo';
import OrderMenu from './pages/OrderMenu';
import ResumoPedido from './pages/Pedido/ResumoPedido';
import Pedidos from './pages/Pedido/Pedidos';
import TipoPizza from './pages/Pizza/TipoPizza';
import PizzaDetails from './pages/Pizza/PizzaDetails';
import Login from './pages/Login';
import CadastrarUsuario from './pages/Cliente/CadastrarUsuario';
import PerfilUsuario from './pages/Cliente/PerfilUsuario';


const App = () => {
  const { isAuthenticated } = useAuth(); // Usando o contexto para verificar autenticação

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastrarusuario" element={<CadastrarUsuario />} />
        <Route path="/perfilusuario" element={<PerfilUsuario />} />
        <Route path="/ordermenu" element={<OrderMenu />} />
        <Route path="/beermenu/:groupId" element={<BeerMenu />} />
        <Route path="/drinkdetails/:id" element={<DrinkDetails />} />
        <Route path="/pizzaDetails/:id/:groupId" element={<PizzaDetails />} />
        <Route path="/tipoPizza/:groupId" element={<TipoPizza />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/pagamento" element={<Pagamento />} />        
        <Route path="/resumo" element={<Resumo />} />
        <Route path="/endereco" element={<Endereco />}/>
        <Route path="/resumo-pedido/:pedidoId" element={<ResumoPedido />} />
        

        {/** Proteja as rotas abaixo */}
        {/*
        <Route
          path="/endereco"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Endereco />} />}
        />
        <Route
          path="/cadastrar-endereco"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<CadastrarEndereco />} />}
        />
        */}
        {/*
        <Route
          path="/pagamento"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Pagamento />} />}
        />
        <Route
          path="/resumo"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Resumo />} />}
        />        
        <Route
          path="/resumo-pedido/:pedidoId"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<ResumoPedido />} />}
        />
        */}
        <Route
          path="/pedidos"
          element={<ProtectedRoute isAuthenticated={isAuthenticated} element={<Pedidos />} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
