// src/pages/Usuario/CadastrarUsuario.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { fetchData, postData, putData } from '../../services/Api';
import { useAuth } from '../../contexts/AuthContext';
import { isValidEmail } from '../../utils/validation';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importando os estilos do Toastify
import food from "../../images/food.ico"; // Ícone para a notificação

import './CadastrarUsuario.css';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import MainContent from '../../components/Form/MainContent';
import Input from '../../components/Input/Input';

const CadastrarUsuario = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  

  const [id, setId] = useState(0);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    senha: '',
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const usuario = await fetchData('/UsuarioApp');
        setId(usuario.id);
        setFormData({
          nome: usuario.nome,
          email: usuario.email,
          telefone: usuario.telefone,
          senha: '',
        });
      } catch (error) {
        console.error('Erro ao obter usuário:', error);
        setErrorMessage('Falha ao carregar os dados do usuário.');
      }
    };

    if (isAuthenticated) {
      fetchUser();
    }

    if (location.state && location.state.usuario) {
      const { id, nome, email, telefone, senha } = location.state.usuario;
      setId(id);
      setFormData({ nome, email, telefone, senha });
    }
  }, [isAuthenticated, location]);

  // Função para lidar com a mudança de inputs e validar
  const handleInputChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));

    // Validação dinâmica
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };

      // Validações específicas para cada campo
      if (field === 'nome' && value.trim() === '') {
        newErrors.nome = 'Nome é obrigatório';
      } else if (field === 'email' && !isValidEmail(value)) {
        newErrors.email = 'Email inválido';
      } else if (field === 'telefone' && value.trim() === '') {
        newErrors.telefone = 'Telefone é obrigatório';
      } else if (field === 'senha' && value.length < 5) {
        newErrors.senha = 'Senha deve ter pelo menos 5 caracteres';
      } else {
        // Remove o erro quando o campo estiver válido
        delete newErrors[field];
      }

      return newErrors;
    });
  };

  const validateForm = () => {
    const { nome, email, telefone, senha } = formData;
    const newErrors = {};

    if (!nome) newErrors.nome = 'Nome é obrigatório';
    if (!email || !isValidEmail(email)) newErrors.email = 'Email inválido';
    if (!telefone) newErrors.telefone = 'Telefone é obrigatório';
    if (!senha || senha.length < 5) newErrors.senha = 'Senha deve ter pelo menos 5 caracteres';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const sendNotification = () => {
    // Usando o react-toastify para mostrar a notificação
    toast.success('Cadastro efetuado com sucesso!', {
      position: "top-right",  // Posição no canto superior direito
      autoClose: 3000,  // Duração da notificação (em ms)
      hideProgressBar: false,  // Exibe ou esconde a barra de progresso
      closeOnClick: true,  // Fecha quando o usuário clicar
      pauseOnHover: true,  // Pausa a notificação ao passar o mouse
      draggable: true,  // Permite arrastar a notificação
      icon: food,  // Ícone para a notificação
    });
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      let response;
      if (id) {
        response = await putData('/UsuarioApp', { id, ...formData });
        if (response === "ok") {
          navigate('/login');
        } else {
          setErrorMessage(response);
        }

      } else {
        
        response = await postData('/UsuarioApp', formData);

        if (response === "ok") {
          //Se for cadastro novo faz o login automaticamente
          response = await postData('/loginApp', {
            email: formData.email,
            senha: formData.senha
          });
    
          // Armazene o token no localStorage ou em outro local seguro
          localStorage.setItem('token', response.token);
    
          // Defina o usuário como autenticado
          setIsAuthenticated(true);

          sendNotification();  // Envia a notificação de sucesso

          if (localStorage.getItem('cart')) {
            navigate('/cart');
          } else {            
            navigate('/');
          }
        } else {
          setErrorMessage(response);
        }
      }

      
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data);
      } else {
        console.error('Erro: ', error);
        setErrorMessage('Ocorreu um erro inesperado.');
      }
    }
  };

  return (
    <>
      <Header titulo="Cadastrar Usuário" />
      <MainContent>
        <div className="formularioContainer">
          <Input            
            value={formData.nome}
            onChange={(e) => handleInputChange('nome', e.target.value)}
            placeholder="Nome Completo"
            error={errors.nome}
            readOnly={isAuthenticated}
          />

          <div className="inputContainer">
            <InputMask
              mask="(99) 9 9999-9999"
              value={formData.telefone}
              onChange={(e) => handleInputChange('telefone', e.target.value)}
              className={`input ${errors.telefone ? 'inputError' : ''}`}
              placeholder="Celular com DDD"
            />
            {errors.telefone && <span className="input-error-message"><br/>{errors.telefone}</span>}
          </div>

          <Input
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder="E-mail"
            error={errors.email}
            readOnly={isAuthenticated}
          />

          <Input
            type="password"
            value={formData.senha}
            onChange={(e) => handleInputChange('senha', e.target.value)}
            placeholder={isAuthenticated ? "Senha Atual" : "Senha"}
            error={errors.senha}
          />

          {errorMessage && <div className="errorMessage">{errorMessage}</div>}

          <button onClick={handleSave} className="button">
            {isAuthenticated ? "Salvar Usuário" : "Cadastrar Usuário"}
          </button>
        </div>
      </MainContent>
      <Footer />
      <ToastContainer /> {/* Componente para renderizar as notificações */}
    </>
  );
};

export default CadastrarUsuario;
