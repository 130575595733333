import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../services/Api';
import Header from '../../components/Form/Header';
import Footer from '../../components/Form/Footer';
import { FaRegCheckSquare, FaSpinner } from "react-icons/fa";
import './ResumoPedido.css';
import MainContent from '../../components/Form/MainContent';
import { BsDot } from 'react-icons/bs';
import { formatCurrency } from '../../utils/numberUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importando o CSS do Toastify
import food from "../../images/food.ico";

const ResumoPedido = () => {
  const { pedidoId } = useParams();
  const [pedido, setPedido] = useState(null);
  const previousStatusRef = useRef(null); // Usado para armazenar o status anterior

  useEffect(() => {
    const fetchPedido = async () => {
      try {
        const result = await fetchData('/PedidoDelivery', { id: pedidoId }); // Passa o id do pedido como parâmetro
        setPedido(result);

        // Verifica se o status mudou
        if (previousStatusRef.current && previousStatusRef.current !== result.statusPedido.descricao) {
          sendNotification(result); // Envia notificação quando o status muda
        }
        previousStatusRef.current = result.statusPedido.descricao; // Atualiza o status anterior
      } catch (error) {
        console.error('Erro ao buscar o pedido:', error);
      }
    };

    // Chama fetchPedido imediatamente
    fetchPedido();

    // Define o intervalo para chamar fetchPedido a cada 15 segundos
    const intervalId = setInterval(fetchPedido, 15000);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, [pedidoId]); // Dependência em pedidoId para refazer a requisição quando ele mudar

  // Função para enviar notificação para o telefone usando o react-toastify
  const sendNotification = (pedido) => {
    toast.info(`Notificação: O status do pedido #${pedido.id} mudou para ${pedido.statusPedido.descricao}`, {
      position: "top-right",  // Posição da notificação
      autoClose: 10000, // Duração da notificação
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: food, // Ícone para a notificação
    });
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleDateString("pt-BR", options);
  };

  const formatHora = (date) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return new Date(date).toLocaleTimeString("pt-BR", options);
  };

  if (!pedido) {
    return <p>Carregando...</p>;
  }

  return (
    <>
      <Header titulo="Resumo do Pedido" routeGoBack="/pedidos" />
      <MainContent>
        <div className="resumo-container">
          <FaRegCheckSquare color={'#27a732'} size={60}/>
          <h3>Pedido {pedido.statusPedido.descricao}</h3>
          {pedido.statusPedido.descricao==='Em Produção' ? 
          (<p>Previsão de Conclusão: {formatHora(pedido.dataHoraPrevisao)}</p>)
          :(<p>Realizado em: {formatDate(pedido.dataHoraPedido)}</p>)}
          
          {pedido.statusPedido.descricao === 'Em análise' && (
            <div className="status-message">
              <FaSpinner className="loading-icon" /> {/* Ícone de loading */}
              <p>Aguarde o estabelecimento confirmar o pedido</p>
            </div>
          )}

          <h4>Itens do Pedido</h4>
          <ul>
            {pedido.pedidoItens.map((item) => (
              <li key={item.id}>
                {item.produto.descricao}, {item.qtde}un., R$ {formatCurrency(item.valor)}
                {!item.pedidoItemSabores && <p>{item.produto.variacao.descricao}</p>}
                {item.observacao && <p>Obs: {item.observacao}</p>}
                
                {/* Renderizar sabor se existir */}
                {item.pedidoItemSabores && item.pedidoItemSabores.length > 0 && (
                  <div className="sabores2">                  
                    <ul>
                      <li>
                        {item.pedidoItemSabores.map((sabor, saborIndex) => (
                          <span key={saborIndex}>
                            <BsDot/>{sabor.sabor.descricao}
                          </span>
                        ))}
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>

          {pedido.endereco && (
            <>
              <h4>Informações de Entrega</h4>
              <p>Endereço: {pedido.endereco.logradouro}, {pedido.endereco.numero}, {pedido.endereco.bairro}, {pedido.endereco.cidade?.nome}/RS</p>
              {pedido.endereco.complemento && <p>Complemento: {pedido.endereco.complemento}</p>}
            </>
          )}

          <p>Cliente: {pedido.cliente.nome}</p>
          <p>Telefone: 55 99677-7532</p>

          <h4>Forma de Pagamento</h4>
          <p>{pedido.tipoPagamento.id === 1 ? 'Dinheiro' : pedido.tipoPagamento.id === 2 ? 'Pix' : 'Cartão'}</p>

          <h4>Valores</h4>
          <p>Valor dos Itens: R$ {formatCurrency(pedido.valorItens)}</p>
          {pedido.taxaEntrega > 0 && <p>Taxa de Entrega: R$ {formatCurrency(pedido.taxaEntrega)}</p>}
          <p><b>Total: R$ {formatCurrency(pedido.valorTotal)}</b></p>
        </div>
      </MainContent>
      <Footer />
      <ToastContainer /> {/* Componente que renderiza as notificações toast */}
    </>
  );
};

export default ResumoPedido;